import { useState, useRef, createContext } from 'react';
import { useParams } from 'react-router-dom';

import useRenderProject from '../hooks/useRenderProject';
import useSubtitleProject from '../hooks/useSubtitleProject';
import useXmlProject from '../hooks/useXmlProject';

const ProjectContext = createContext(null);

const ProjectContextProvider = ({ children }) => {
	const { id } = useParams("id");

	const [currentVideoType, setCurrentVideoType] = useState('original');

	const videoRef = useRef(null);
	const videoRefPreview = useRef(null);

	const {
		project,
		projectName,
		videoSource,
		videoPreviewSource,
		controls,
		offset,
		qpos,
		switchPlanTimeCheck,
		duration,
		showError,
		renderError,
		setShowError,
		setRenderError,
		setOffset,
		handleSubmit,
		handlePreview,
		updateProject,
		removeProject
	} = useRenderProject({ id, videoRef, videoRefPreview})

	const {
		subtitlesUrl,
		subtitleRender,
		subtitleCheck,
		subtitleSegments,
		subtitleCutLoader,
		subtitleLoader,
		addSubtitles,
		updateSubtitles,
		setModifiedSegments,
		setSubtitleCheck
	} = useSubtitleProject({ id, updateProject });

	const { isLoadingFiles, createXmlAndDownload } = useXmlProject({ id });

	const contextValue = {
		render: {
			id,
			project,
			projectName,
			videoSource,
			videoPreviewSource,
			offset,
			qpos,
			switchPlanTimeCheck,
			duration,
			showError,
			renderError,
			setShowError,
			setRenderError,
			setOffset,
			handleSubmit,
			handlePreview,
			updateProject,
			removeProject
		},
		video: {
			videoRef,
			videoRefPreview,
			currentVideoType,
			setCurrentVideoType
		},
		controls,
		subtitle: {
			subtitlesUrl,
			subtitleRender,
			subtitleCheck,
			subtitleSegments,
			subtitleCutLoader,
			subtitleLoader,
			addSubtitles,
			updateSubtitles,
			setModifiedSegments,
			setSubtitleCheck
		},
		xml: {
			isLoadingFiles,
			createXmlAndDownload
		}
	};

	return (
		<ProjectContext.Provider value={contextValue}>
			{children}
		</ProjectContext.Provider>
	);
};

export { ProjectContext, ProjectContextProvider };