import { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InputValidate from '../../components/input/InputValidate';
import { FormErrors } from '../../components/input/FormErrors';
import { ButtonSubmit } from '../../components/button/ButtonSubmit';

import { UserProfile } from '../../contracts/userProfile';
import ProfileClient from '../../api/profileClient';

function ProfileForm() {
	const {
		register: registerAccountSettings,
		handleSubmit: handleSubmitAccountSettings,
		formState: { errors: accountSettingsFormErrors },
		setValue: accountSettingsSetValue
	} = useForm();

	const [accountSettingsErrors, setAccountSettingsErrors] = useState({});
	const [isAccountSettingsSuccess, setAccountSettingsSuccess] = useState(false);
	const [profileId, setProfileId] = useState('');

	const { t } = useTranslation();
	const profileClient = useMemo(() => new ProfileClient(), []);

	const onSubmitAccountSettings = async (data) => {
		const userUpdate = new UserProfile();

		userUpdate.firstName = data.firstName;
		userUpdate.lastName = data.lastName;
		userUpdate.phoneNumber = data.phoneNumber;

		const result = await profileClient.update(profileId, userUpdate);

		if (result.successful) {
			setAccountSettingsErrors({});
		} else {
			setAccountSettingsErrors(result.errors);
		}

		setAccountSettingsSuccess(result.successful);
	};

	const handleChangeAccount = () => {
		setAccountSettingsSuccess(false);
	};

	const getProfileData = useCallback(async () => {
		const profileData = await profileClient.get();

		if (profileData == null) return;

		setProfileId(profileData.userId);

		accountSettingsSetValue("firstName", profileData.firstName);
		accountSettingsSetValue("lastName", profileData.lastName);
		accountSettingsSetValue("phoneNumber", profileData.phoneNumber);
	}, [accountSettingsSetValue, profileClient]);

	useEffect(() => {
		getProfileData();
	}, [getProfileData]);

	return (
		<form
			className="row g-3"
			onSubmit={handleSubmitAccountSettings(onSubmitAccountSettings)}
			onChange={handleChangeAccount}
		>
			<div className="col-6">
				<InputValidate
					label={t("firstName")}
					name="firstName"
					register={registerAccountSettings}
				/>
			</div>
			<div className="col-6">
				<InputValidate
					label={t("secondName")}
					name="lastName"
					register={registerAccountSettings}
				/>
			</div>
			<div className="col-12">
				<InputValidate
					label={t("phone")}
					name="phoneNumber"
					register={registerAccountSettings}
					errors={accountSettingsFormErrors}
					validation={{
						pattern: {
							value: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
							message: t("wrongFormat")
						}
					}}
				/>
			</div>
			<FormErrors
				errors={accountSettingsErrors}
				successful={isAccountSettingsSuccess}
				defaultMessage={t("saveSuccess")}
			/>
			<div className="d-flex justify-content-center gap-2">
				<ButtonSubmit text={t("save")} />
			</div>
		</form>
	);
}

export default ProfileForm;
