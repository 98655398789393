function FormErrors({ errors, formErrors, successful, defaultMessage }) {
	if (successful) {
		return (
			<div className="d-flex  valid-feedback" role="alert">
				{defaultMessage}
			</div>
		);
	}

	return (
		errors != null && Object.keys(errors).length > 0 ?
			<div>
				{
					Object.keys(errors).map((key) => {
						return (
							<div key={key} className="d-flex invalid-feedback" role="alert">
								{errors[key]}
							</div>
						);
					})
				}
			</div>
		:
			null
	);
}

export { FormErrors };