import './App.scss';
import '../i18n.js';

import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation} from 'react-router-dom';

import AuthHelper from '../helpers/authHelper';

import Layout from '../components/layout';
import ProjectListPage from '../pages/projectListPage';
import NewProjectPage from '../pages/newProjectPage';
import ProjectPage from '../pages/projectPage';
import LoginPage from '../pages/auth/loginPage';
import RegisterPage from '../pages/auth/registerPage';
import ProfilePage from '../pages/profilePage';
import SharedPage from '../pages/sharedPage';
import FaqPage from '../pages/faqPage';
import ResetPasswordPage from '../pages/auth/resetPasswordPage';

const ProtectedRoute = ({ anonym, children }) => {
  const authHelper = new AuthHelper();

  const token = authHelper.getToken();
  const location = useLocation();

  if (anonym) {
    if (token) {
      return <Navigate to="/" replace state={{ from: location }} />;
    }

    return children;
  }

  if (!token) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};

function App() {
  // script for yandex share
  useEffect(() => {
    const script = document.createElement("script");
    
    script.src = "https://yastatic.net/share2/share.js";
    script.async = true;
    script.crossOrigin = "anonymous";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route
            path="/"
            element={<ProtectedRoute children={<ProjectListPage />} />}
          />
          <Route
            path="/project"
            element={<ProtectedRoute children={<NewProjectPage />} />}
          />
          <Route
            path="/project/:id"
            element={<ProtectedRoute children={<ProjectPage />} />}
          />
          <Route
            path="/profile"
            element={<ProtectedRoute children={<ProfilePage />} />}
          />
          <Route
            path="/login"
            element={<ProtectedRoute children={<LoginPage />} anonym={true} />}
          />
          <Route
            path="/sign-up"
            element={<ProtectedRoute children={<RegisterPage />} anonym={true} />}
          />
          <Route path="/v/:share" element={<SharedPage />} />
          <Route path="/faq" element={<FaqPage />}/> 
          <Route path="/changePassword" element={<ResetPasswordPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;