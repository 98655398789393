import Card from '../../components/card/Card';
import ProjectForm from '../../widgets/projectForm';
import ProjectRenderError from '../../widgets/projectRenderError';

import { ProjectContextProvider } from '../../context/projectContext';

function ProjectPage() {
	return (
		<ProjectContextProvider>
			<Card>
				<ProjectRenderError />
				<ProjectForm />
			</Card>
		</ProjectContextProvider>
	);
};

export default ProjectPage;