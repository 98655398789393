class AuthHelper {
	getToken() {
		return localStorage.getItem('token');
	}

	setToken(token) {
		localStorage.setItem('token', token);
	}

	clearToken() {
		localStorage.removeItem('token');
	}

	authHeader() {
		const user = localStorage.getItem('token');

		if (user) {
			return { Authorization: 'Bearer ' + user.accessToken };
		} else {
			return {};
		}
	}

	parseJwt(token) {
		try {
			return JSON.parse(atob(token.split('.')[1]));
		} catch (e) {
			return null;
		}
	};

	getUserContext() {
		const token = this.getToken();
		
		return this.parseJwt(token);
	}

	clearUserContext() {
		this.clearToken();
	}
}

export default AuthHelper;