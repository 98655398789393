import AuthHelper from '../helpers/authHelper';

import { Config } from '../config';

class ProfileClient {
	constructor() {
		this.authHelper = new AuthHelper();
	}

	/**
	 * Получение пользователя по токену из LocalStorage
	 * 
	 * @returns {object} - объект с пользователем
	 */
	async get() {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.authHost}/user`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});
	
			if (!res.ok) throw new Error(res.statusText);
			
			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Изменение пароля
	 * 
	 * @param {{oldPassword: string, newPassword: string}} userChangeSecret - объект с паролями
	 * @returns {object} - результат изменения
	 */
	async changeSecretAsync(userChangeSecret) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.authHost}/user/password/change`, {
				method: "POST",
				body: JSON.stringify(userChangeSecret),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});

			if (!res.ok) throw new Error(res.statusText);
	
			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Обновление профиля
	 * 
	 * @param {string} userId - id пользователя
	 * @param {Object} userInfo - параметры пользователя
	 * @param {string} userInfo.firstName - имя
	 * @param {string} userInfo.lastName - фамилия
	 * @param {string} userInfo.phoneNumber - номер телефона
	 * @returns {object} - результат обновления
	 */
	async update(userId, userInfo) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.authHost}/user/${userId}`, {
				method: "PUT",
				body: JSON.stringify(userInfo),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});
	
			if (!res.ok) throw new Error(res.statusText);

			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Сброс пароля
	 * 
	 * @returns {object} - результат сброса
	 */
	async resetPass(email) {
		try {
			const res = await fetch(`${Config.authHost}/user/password/reset?email=${email}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				}
			});
	
			if (!res.ok) throw new Error(res.statusText);
	
			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Установка нового пароля
	 * 
	 * @param {Object} data - параметры
	 * @param {string} data.password - новый пароль
	 * @param {string} data.confirmPassword - подтверждение пароля
	 * @returns {object} - результат
	 */
	async setNewPassword(data) {
		try {
			const res = await fetch(`${Config.authHost}/user/password/setnewpassword`, {
					method: "POST",
					headers: {
							"Content-Type": "application/json",
					},
					body: JSON.stringify(data),
			});
		
			if (res.status === 204) {
				return null;
			}

			const json = await res.json();
			return json;
		} catch (error) {
			console.error(error);

			throw error;
		}
	}
}

export default ProfileClient;