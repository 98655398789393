import React from 'react';

import { BrowserRouter, } from 'react-router-dom';
import { ThemeProvider } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
// import { Provider } from 'react-redux';

// import { store } from './app/store';
import reportWebVitals from './reportWebVitals';

import App from './app/App';

document.body.classList.add("d-flex", "flex-column", "h-100");
document.getElementsByTagName("html")[0].classList.add("h-100");

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<React.StrictMode>
    <BrowserRouter>
      {/* <Provider store={store}> */}
        <ThemeProvider dir="rtl">
          <App />
        </ThemeProvider>
    	{/* </Provider> */}
  	</BrowserRouter>
	</React.StrictMode>
);

reportWebVitals();
