import AuthHelper from '../helpers/authHelper';

import { Config } from '../config';

class subtitlesClient {
	constructor() {
		this.authHelper = new AuthHelper();
	}

	/**
	 * Отправка запроса на рендер субтитров
	 * @param {string} projectId - id проекта
	 * @returns {object} - объект с рендером id
	 */
	async addSubtitles(projectId) {
		let subtitlesOptions = {
			projectId: projectId,
			shouldTranslate: false,
			languageCode: 'ru',
		};
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.subtitles}/api/Subtitles`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				body: JSON.stringify(subtitlesOptions)
			});
	
			if (!res.ok) throw new Error(res.statusText);

			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Получение рендера субтитров
	 * 
	 * @param {string} id - id рендеринга
	 * @returns {object} - объект с информацией о рендере субтитров
	 */
	async getSubtitles(id) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.subtitles}/api/Subtitles?renderProjectId=${id}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			});
			
			if (!res.ok) throw new Error(res.statusText);
	
			return await res.json();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Получение готовых субтитров в формате WebVTT
	 * 
	 * @param {string} id - id рендеринга
	 * @returns {string|null} - текст субтитров в формате WebVTT
	 */
	async getSubtitlesWebVtt(id) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.subtitles}/api/Storage/${id}`, {
				method: "GET",
				headers: {
					"Content-Type": "text/vtt",
					Authorization: `Bearer ${token}`
				}
			});
	
			if (res.status === 404) return null;
			
			if (!res.ok) throw new Error(res.statusText);
	
			return await res.text();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}

	/**
	 * Обрезка сегментов по субтитрам
	 * 
	 * @param {object[]} subtitleSegments - массив сегментов субтитров,
	 * @returns {object} - объект с информацией о результате обрезки
	 */
	async cutSegments(subtitleSegments) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.subtitles}/api/Subtitles/cutSegments`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				body: JSON.stringify(subtitleSegments)
			});
	
			return await res.json();
		} catch (error) {
			console.error(error);
			
			throw error;
		}
	}

	/**
	 * Загрузка zip-архива с файлами проекта
	 * 
	 * @param {string} id - id проекта
	 * @returns {Blob} - архив с файлами
	 */
	async downloadFiles(id) {
		const token = this.authHelper.getToken();

		try {
			const res = await fetch(`${Config.host}/storage/${id}/files`, {
				method: "GET",
				headers: {
					"Content-Type": "application/octet-stream",
					"Content-Disposition": `attachment; filename=project_files_${id}.zip`,
					Authorization: `Bearer ${token}`
				}
			});

			if (!res.ok) throw new Error(res.statusText);
			
			return await res.blob();
		} catch (error) {
			console.error(error);

			throw error;
		}
	}
}

export default subtitlesClient;