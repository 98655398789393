import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useControlsProject from './useControlsProject'; 

import { confirmAlert } from 'react-confirm-alert';

import { Config } from '../config';
import { Settings } from '../contracts/project';
import AuthHelper from '../helpers/authHelper';

import RenderClient from '../api/renderClient';
import ProjectClient from '../api/projectClient';

import { timeSpanToSec } from '../helpers/timeHelper';

const useRenderProject = ({ id, videoRef, videoRefPreview }) => {
	const [project, setProject] = useState();
	const [projectName, setProjectName] = useState("");

	const [videoSource, setVideoSource] = useState("");
	const [videoPreviewSource, setVideoPreviewSource] = useState("");

	const updateIntervalRef = useRef(null);
	const [updateStatus, setUpdateStatus] = useState(false);
	
	const [qpos, setQPos] = useState(-1);
	const [switchPlanTimeCheck, setCheck3] = useState(false);
	// const [options, $options] = useState();
	const [duration, $duration] = useState(0);
	const [audioSourceIndex, $audioSourceIndex] = useState(0);
	const [offset, setOffset] = useState([0, 0]);

	const [showError, setShowError] = useState(true);
	const [renderError, setRenderError] = useState(false);

	const navigate = useNavigate();
	const { t } = useTranslation();
	const controls = useControlsProject();

	const {
		minSwitchTime,
		speedUpSilenceCheck,
		speedUpAllCheck,
		isVerticalCheck,
		speedUpSilence,
		speedUpAll,
		switchTime,
		setSpeedUpSilenceCheck,
		setSpeedUpAllCheck,
		setIsVerticalCheck,
		setSpeedUpSilence,
		setSpeedUpAll,
		setSwitchTime
	} = controls;

	const authHelper = useMemo(() => new AuthHelper(), []);
	const projectClient = useMemo(() => new ProjectClient(), []);
	const renderClient = useMemo(() => new RenderClient(), []);

	const getVideoSource = useCallback(async (id, proj) => {
		const token = authHelper.getToken();
		let result = '';
		let videoUrl = `${Config.host}/storage/${id}/stream/`;

		if (token != null) {
			const url = new URL(videoUrl);

			url.searchParams.set("token", token);
			url.searchParams.set("key", proj.renderKey);

			videoUrl = url.toString();
		}

		let res = await projectClient.get(id);

		if (res.status === 4) {
			await renderClient.isFileExists(videoUrl)
			.then(rc => {
				setVideoSource(rc.url);
				result = rc.url;
			})
			.catch(er => {
				setVideoSource(null);
				result = null;
			});
		}
		
		return result;
	}, [authHelper, projectClient, renderClient]);

	const getPreviewVideoSource = useCallback(async (id, proj) => {
		const token = authHelper.getToken();
		let result = '';
		let videoPreviewUrl = `${Config.host}/storage/${id}/previewstream/`;

		if (token != null) {
			const previewUrl = new URL(videoPreviewUrl);

			previewUrl.searchParams.set("token", token);
			previewUrl.searchParams.set("key", proj.renderKey);
			
			videoPreviewUrl = previewUrl.toString();
		}
			
		let res = await projectClient.get(id);
		
		if (res.status === 4) {
			await renderClient.isFileExists(videoPreviewUrl)
			.then(rc => {
				setVideoPreviewSource(rc.url);
				result = rc.url;
			}).catch(er => {
				setVideoPreviewSource(null);
				result = null;
			});
		}

		return result;
	}, [authHelper, projectClient, renderClient]);

	const updateVideoSource = useCallback(async (id, proj) => {
		await getVideoSource(id, proj);
		await getPreviewVideoSource(id, proj);

		if (videoRef.current && videoRef.current.getInternalPlayer()) {
			videoRef.current.getInternalPlayer().load();
		}
		if (videoRefPreview.current && videoRefPreview.current.getInternalPlayer()) {
			videoRefPreview.current.getInternalPlayer().load();
		}
	}, [getPreviewVideoSource, getVideoSource, videoRef, videoRefPreview]);

	const updateProject = useCallback(async (project) => {
		if (project == null) {
			setRenderError(true);
			
			return;
		}

		setProject(project);
		setProjectName(project.name);
		// const opts = Object.keys(project.fileNames).map((x, i) => ({
		// 	value: i,
		// 	label: x,
		// }));
		// $options(opts);
		setQPos(project.positionInQueue);
		setCheck3(true);
		const stg = project.settings;
		let duration = 0;
		if (project?.referenceVideo?.size?.duration) {
			duration = timeSpanToSec(project.referenceVideo.size.duration);
			$duration(duration);
			setOffset([0, duration]);
		}

		if (stg !== null && stg !== undefined) {
			const truncate = stg.truncateSec || duration;
			setOffset([stg.offsetSec, truncate]);
			setSpeedUpSilenceCheck(stg.needSpeedUpSilence);
			setSpeedUpAllCheck(stg.needSpeedUpAll);
			setIsVerticalCheck(stg.isVertical);
			setSpeedUpSilence(stg.speedUpSilence);
			setSpeedUpAll(stg.speedUpAll);
			setSwitchTime(
				stg.switchTime < minSwitchTime ? minSwitchTime : stg.switchTime
			);
			$audioSourceIndex(stg.audioSourceIndex);
		}

		await updateVideoSource(id, project);
	}, [
		id,
		minSwitchTime,
		setIsVerticalCheck,
		setSpeedUpAll,
		setSpeedUpAllCheck,
		setSpeedUpSilence,
		setSpeedUpSilenceCheck,
		setSwitchTime,
		updateVideoSource
	]);

	const render = async () => {
		setUpdateStatus(true);
		if (videoRef.current) {
			videoRef.current.getInternalPlayer().pause();
		}

		if (videoRefPreview.current) {
			videoRefPreview.current.getInternalPlayer().pause();
		}

		var settings = new Settings();

		settings.needSpeedUpSilence = speedUpSilenceCheck;
		settings.needSpeedUpAll = speedUpAllCheck;
		settings.isVertical = isVerticalCheck;
		settings.speedUpSilence = speedUpSilence ? speedUpSilence : settings.speedUpSilence;
		settings.speedUpAll = speedUpAll ? speedUpAll : settings.speedUpAll;
		settings.switchTime = switchTime;
		settings.audioSourceIndex = audioSourceIndex;
		settings.offsetSec = offset[0];
		settings.truncateSec = offset[1];

		try {
			let res = await renderClient.render(id, settings);
			setQPos(res.positionInQueue);

			res = await projectClient.get(id);
			await (res);
		} catch (error) {
			console.log(error);

			clearInterval(updateIntervalRef.current);
			setUpdateStatus(false);

			return;
		}
	};

	const renderPreview = async () => {
		setUpdateStatus(true);

		if (videoRef.current) {
			videoRef.current.getInternalPlayer().pause();
		}
		if (videoRefPreview.current) {
			videoRefPreview.current.getInternalPlayer().pause();
		}
		
		try {
			let res = await renderClient.renderPreview(id);
			setQPos(res.positionInQueue);

			res = await projectClient.get(id);
			await updateProject(res);
		} catch (error) {
			console.log(error);
			
			clearInterval(updateIntervalRef.current);
			setUpdateStatus(false);
			
			return;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		
		setProject({ status: 3 });
		render();
	};

	const handlePreview = async(e) => {
		e.preventDefault();
		
		setProject({ status: 3 });
		renderPreview();
	};

	const removeProject = async () => {
		confirmAlert({
			title: t("sure"),
			message: t("reallySure"),
			buttons: [
				{
					label: t("yes"),
					onClick: async () => {
						await projectClient.remove(id);
						navigate(`/`);
					},
				},
				{
					label: t("no"),
					onClick: () => { },
				},
			],
		});
	};

	const handleUpdateProject = useCallback(async () => {
		let res = await projectClient.get(id);
		
		await updateProject(res);
	}, [id, projectClient, updateProject]);

	const updateInterval = useCallback(() => {
		updateIntervalRef.current = setInterval(async () => {
			try {
				let res = await projectClient.get(id);
				await updateProject(res);

				if (res == null || res.status !== 3) {
					const videoElement = document.getElementById("generalVideoID");
					const previewVideoElement = document.getElementById("previewVideoID");
					
					const videoSrc = await getVideoSource(id, res);
					const previewSrc = await getPreviewVideoSource(id, res);

					if (videoSrc?.length > 0 && videoElement) {
						videoElement.src = videoSrc;
					}

					if (previewSrc?.length > 0 && previewVideoElement) {
						previewVideoElement.src = previewSrc;
					}

					clearInterval(updateIntervalRef.current);
					setUpdateStatus(false);
				}
			} catch (err) {
				console.error(err);
				setUpdateStatus(false);
			}
		}, 2000);
	}, [getPreviewVideoSource, getVideoSource, id, projectClient, updateProject]);

	useEffect(() => {
		handleUpdateProject();
	}, [handleUpdateProject]);

	useEffect(() => {
		if (updateStatus) {
			updateInterval();
	
			return () => {
				if (updateIntervalRef.current) {
					clearInterval(updateIntervalRef.current);
				}
			};
		}
	}, [updateInterval, updateStatus]);

	return {
		project,
		projectName,
		videoSource,
		videoPreviewSource,
		offset,
		qpos,
		switchPlanTimeCheck,
		duration,
		showError,
		renderError,
		setRenderError,
		setShowError,
		setOffset,
		handleSubmit,
		handlePreview,
		removeProject,
		updateProject,
		controls
	}
};

export default useRenderProject;
