import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Download } from 'react-bootstrap-icons';

import ShareVideo from '../../widgets/shareVideo';

import { ProjectContext } from '../../context/projectContext';

function ProjectBar() {
	const { t } = useTranslation();

	const context = useContext(ProjectContext);

	const { project, videoSource, videoPreviewSource } = context?.render;
	const { currentVideoType } = context?.video;

	return (
		project?.status === 4 && (
			<>
				<ShareVideo
					className="ms-1"
					projectId={project.id}
					videoType={currentVideoType}
				/>
				<a
					title={t("downloadMainVideo")} 
					style={{color:'blue'}}
					className="ms-1 btn btn-dark" 
					href={videoSource}
				>
					<Download />
				</a>
				{
					videoPreviewSource?.length > 0 ? (
						<a
							title={t("downloadPreview")}
							style={{ color: 'lightgreen' }}
							className="ms-1 btn btn-dark"
							href={videoPreviewSource}
						>
							<Download />
						</a>)
					: null
				}
			</>
		)
	);
}

export default ProjectBar;