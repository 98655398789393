import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import carousel_data from './module.data';

function FaqCarousel() {
	const { t } = useTranslation();
	
  return (
		<Carousel data-bs-theme="dark" pause={'hover'}>
			{
				carousel_data.map((slide) => {
					return (
						<Carousel.Item interval={100000000000000}>
							<img
								className="d-block w-100 carousel-img"
								src={slide.src}
								alt={slide.alt}
							/>
							<Carousel.Caption>
								<h3 className="carousel-text">{t(slide.heading)}</h3>
								<p className="carousel-text">{t(slide.paragraph)}</p>
							</Carousel.Caption>
						</Carousel.Item>
					);
				})
			}
		</Carousel>
  );
}

export default FaqCarousel;