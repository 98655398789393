import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AuthHelper from '../../helpers/authHelper';

import logo from '../../assets/logo.png';
import { Telegram } from 'react-bootstrap-icons';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavbarRoot from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import LanguageSelector from '../language/languageSelector';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  const navigate = useNavigate();
  
  const authHelper = new AuthHelper();
  const userContext = authHelper.getUserContext();

  const { t } = useTranslation();

  const onLogout = () => {
    authHelper.clearUserContext();
    navigate("/");
  };

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  return (
    <NavbarRoot
      expand="lg"
      bg="blue"
      data-bs-theme="light"
      sticky="top"
      className="nav_animate"
    >
      <Container>
        <NavbarRoot.Brand href="/">
          <img
            height={100}
            src={logo}
            alt="logo"
          />
        </NavbarRoot.Brand>
        <NavbarRoot.Toggle aria-controls="basic-navbar-nav" />
        <NavbarRoot.Offcanvas
          id={`offcanvasNavbar-expand`}
          data-bs-theme="light"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
              {t("navigation")}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-grow-1 pe-3 justify-content-end">
              <Nav.Link href="/faq">
                {t("instruction")}
              </Nav.Link>
            </Nav>
            <Nav className="pe-3 justify-content-end">
              <Nav.Link
                href="https://t.me/videoneuron_chat"
                target='_blank'
              >
                <Telegram size={24} className="tg_button"/>
              </Nav.Link>
            </Nav>
            <Nav className="justify-content-end">
              <NavDropdown
                id="basic-nav-dropdown"
                title={t("language")}
              >
                <LanguageSelector/>
              </NavDropdown>
            </Nav>
            {
              userContext &&
                <Nav className="justify-content-end pe-3">
                  <NavDropdown title={userContext.email} id="basic-nav-dropdown">
                    <NavDropdown.Item href="/profile">{t("accountSettings")}</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={onLogout}>{t("exit")}</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
            }
          </Offcanvas.Body>
        </NavbarRoot.Offcanvas>
      </Container>
    </NavbarRoot>
  );
}

export default Navbar;